import { createEvent, createStore, sample } from 'effector'
import { reset } from 'patronum'

import {
  AssistanceFormInputType,
  AssistanceFormChangedValueEvent,
  AssistanceFormRadioQuestion,
  AssistanceFormResponses,
} from './types'

export const assistanceFormChanged =
  createEvent<AssistanceFormChangedValueEvent>()

export const assistanceFormReset = createEvent<void>()
export const assistanceFormSubmitted = createEvent<void>()
export const assistanceFormValidated = createEvent<AssistanceFormResponses>()

export const $assistanceForm = createStore<AssistanceFormResponses>({})

export const $assistanceRadioQuestions = createStore<
  AssistanceFormRadioQuestion[]
>([])

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.Phone,
  fn: (formData, { value }) => ({ ...formData, phone: value }),
  target: $assistanceForm,
})

sample({
  clock: assistanceFormChanged,
  source: $assistanceForm,
  filter: (_, { type }) => type === AssistanceFormInputType.Email,
  fn: (formData, { value }) => ({ ...formData, email: value }),
  target: $assistanceForm,
})

reset({
  clock: assistanceFormReset,
  target: [$assistanceForm, $assistanceRadioQuestions],
})
