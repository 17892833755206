import { createRoute } from '@tanstack/react-router'

import { rootRoute } from './root'

export const secureScope = createRoute({
  getParentRoute: () => rootRoute,
  id: '@secure',
  beforeLoad: async () => {
    // TODO: if authenticated, force @public
  },
})

export const publicScope = createRoute({
  getParentRoute: () => rootRoute,
  id: '@public',
  beforeLoad: async () => {
    // TODO: if authenticated, force @secure
  },
})
