import { combine, createStore, sample } from 'effector'
import { reset } from 'patronum'

import { EMAIL_PATTERN } from 'src/sections/SectionAuth/PasswordEntry/signHelpers'
import { remap } from 'src/shared/lib/effector/remap'
import { validatePhone } from 'src/utils/phoneHelpers'

import {
  assistanceFormSubmitted,
  assistanceFormReset,
  $assistanceForm,
  assistanceFormChanged,
} from './core'
import { GetAssistanceGate } from './effects'
import {
  applicableAssistanceErrors,
  getAssistanceFormErrorsMessages,
  assistanceInputsErrors,
} from './helpers'
import {
  AssistanceFormErrors,
  AssistanceFormErrorType,
  AssistanceFormInputType,
  ValidationInputType,
} from './types'

export const $currentAssistanceFormErrors = createStore<
  AssistanceFormErrorType[]
>([])

export const $hasAssistanceFormError = $currentAssistanceFormErrors.map(
  (errors) => Boolean(errors.length),
)

export const $getAssistanceFormErrors = $currentAssistanceFormErrors.map(
  (currentErrors) =>
    currentErrors.reduce((acc, err) => {
      acc[err] = getAssistanceFormErrorsMessages()[err]
      return acc
    }, {} as AssistanceFormErrors),
)

const $assistanceFormValidationErrors = combine(
  $assistanceForm,
  GetAssistanceGate.state,
  (
    { email = '', phone = '' },
    { email: prefilledEmail },
  ): Record<AssistanceFormErrorType, boolean> => ({
    [AssistanceFormErrorType.EmailRequired]: !email && !prefilledEmail,
    [AssistanceFormErrorType.Email]:
      email.length > 0 && !EMAIL_PATTERN.test(email),
    [AssistanceFormErrorType.Phone]: phone.length > 0 && !validatePhone(phone),
  }),
)

sample({
  clock: assistanceFormSubmitted,
  source: {
    errors: $assistanceFormValidationErrors,
    screenType: remap(GetAssistanceGate.state, 'screenType'),
  },
  fn: ({ errors, screenType }) =>
    applicableAssistanceErrors[screenType].filter(
      (errorKey) => errors[errorKey],
    ),
  target: $currentAssistanceFormErrors,
})

sample({
  clock: assistanceFormChanged,
  source: $currentAssistanceFormErrors,
  filter: (_, { type }) =>
    type === AssistanceFormInputType.Email ||
    type === AssistanceFormInputType.Phone,
  fn: (currentErrors, { type }) =>
    currentErrors.filter(
      (err) =>
        !assistanceInputsErrors[type as ValidationInputType].includes(err),
    ),
  target: $currentAssistanceFormErrors,
})

reset({
  clock: assistanceFormReset,
  target: $currentAssistanceFormErrors,
})
