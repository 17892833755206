import { createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { condition, equals } from 'patronum'

import { UserLocationsStep } from '../types'

export const UserLocationsGate = createGate('UserLocationsGate')

export const stepChanged = createEvent<UserLocationsStep>()
export const stepChangedBack = createEvent<void>()

export const $step = createStore<UserLocationsStep>(UserLocationsStep.List)
  .on(stepChanged, (_, step) => step)
  .reset(UserLocationsGate.close)

export const $isClosable = equals($step, UserLocationsStep.List)

condition({
  source: sample({ clock: stepChangedBack, source: $step }),
  if: equals($step, UserLocationsStep.Edit),
  then: stepChanged.prepend(() => UserLocationsStep.Search),
  else: stepChanged.prepend(() => UserLocationsStep.List),
})
