import { memo } from 'react'

import { useLocation } from '@reach/router'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'

import { Sprite } from '@shared/ui/Sprite'

import { ROUTES } from 'src/constants/routesConstants'
import { useOrganizationPhoneQuery } from 'src/entities/organization'
import { withDispatch } from 'src/store'
import {
  setIsShowGuidesPhoneNumberModal,
  setIsShowThirdSectionModal,
  setThirdSectionContent,
  THIRD_SECTION_CONTENT,
} from 'src/store/slices/common'
import { primaryWhite, secondaryGrey } from 'src/theme/colors'
import { formatPhone, PHONE_FORMATS } from 'src/utils/phoneHelpers'
import { convertPxToRem, isMobileView } from 'src/utils/responsiveHelpers'
import { checkPathName } from 'src/utils/routeHelpers'

interface ButtonCall40Props extends ConnectedProps<typeof connector> {
  fill?: string
  bgColor?: string
  withShadow?: boolean
  width?: string
  height?: string
  preferredPhone?: string
  isDisabled?: boolean
  onClick?: (() => void) | null
}

function ButtonCall40({
  fill = secondaryGrey,
  bgColor = primaryWhite,
  withShadow = false,
  width = convertPxToRem(40),
  height = convertPxToRem(40),
  preferredPhone = '',
  isDisabled = false,
  onClick = null,
  dispatchOpenPhoneNumberModal,
  dispatchSetDesktopPhoneSelection,
  dispatchSetIsShowThirdSectionModal,
}: ButtonCall40Props) {
  const { navigator: navigatorPhone } = useOrganizationPhoneQuery()

  const location = useLocation()
  const isMedicineCabinetRoute =
    checkPathName(ROUTES.MEDICATIONS_CABINET_PATH, location) ||
    checkPathName(ROUTES.MEDICATIONS_CARE_PATH, location)

  const handleClick = () => {
    if (isDisabled) return

    if (typeof onClick === 'function') {
      // Custom behavior requested
      onClick()
    } else {
      if (!isMobileView) {
        dispatchSetIsShowThirdSectionModal()
        dispatchSetDesktopPhoneSelection()
      }

      if (isMedicineCabinetRoute && isMobileView) dispatchOpenPhoneNumberModal()
    }
  }

  const getHref = () => {
    const formattedPhone =
      formatPhone(preferredPhone, PHONE_FORMATS.uri) ||
      formatPhone(navigatorPhone, PHONE_FORMATS.uri)

    const value =
      isDisabled || isMedicineCabinetRoute || !isMobileView || onClick
        ? null
        : formattedPhone

    return value ?? undefined
  }

  return (
    <CallLink
      style={{ width, height, backgroundColor: bgColor }}
      href={getHref()}
      onClick={handleClick}
      data-disabled={isDisabled}
      data-with-shadow={withShadow}
    >
      <CallIcon fill={fill} />
    </CallLink>
  )
}

const CallLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  border: none;

  cursor: pointer;
  &[data-disabled='true'] {
    cursor: inherit;
  }

  &[data-with-shadow='true'] {
    box-shadow: ${convertPxToRem(0, 4, 16)} rgba(206, 206, 206, 0.5);
  }

  :active,
  :hover,
  :focus {
    outline: none;
  }
`

const CallIcon = styled(Sprite).attrs({ name: 'misc/call' })<{ fill: string }>`
  height: ${convertPxToRem(24)};
  width: ${convertPxToRem(24)};
  color: ${({ fill }) => fill && fill};
`

const mapDispatchToProps = withDispatch({
  openPhoneNumberModal: () => setIsShowGuidesPhoneNumberModal(true),
  setDesktopPhoneSelection: () =>
    setThirdSectionContent(THIRD_SECTION_CONTENT.SELECT_PHONE),
  setIsShowThirdSectionModal: () => setIsShowThirdSectionModal(true),
})

const connector = connect(null, mapDispatchToProps)
export default connector(memo(ButtonCall40))
