import { createEvent, createStore } from 'effector'

import { RemoteError } from './types'

export const remoteErrorReceived = createEvent<RemoteError>()
export const remoteErrorClosed = createEvent<void>()

export const $remoteError = createStore<RemoteError | null>(null)
  .on(remoteErrorReceived, (_, error) => error)
  .reset(remoteErrorClosed)
