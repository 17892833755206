import { memo } from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import assistanceIcon from 'src/assets/images/assistanceIcon.png'
import IconCounter from 'src/components/ImgIcons/IconCounter'
import IconIndicator from 'src/components/ImgIcons/IconIndicator'
import {
  useInboxStatusQuery,
  useInboxStatusUpdatesSubscription,
} from 'src/features/InboxStatus'
import { openInbox, startNewChat } from 'src/store/slices/chats'
import { setIsShowThirdSectionModal } from 'src/store/slices/common'
import { IconBoxShadow } from 'src/theme/baseStyles'
import { primaryWhite, sweetBlue } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

// TODO: will be reworked within the NEX-19411

function NavigatorIconLightTheme({
  isPreventOnClick = false,
  isInbox = false,
  showThirdSectionModal = () => {},
  openNewChat = () => {},
  goToInbox = () => {},
}) {
  const { unreadCount } = useInboxStatusQuery()

  useInboxStatusUpdatesSubscription()

  const handleClick = () => {
    if (isPreventOnClick) return
    showThirdSectionModal()
    if (unreadCount) {
      goToInbox()
      return
    }
    openNewChat()
  }

  return (
    <Root onClick={handleClick}>
      <AssistanceImgWrapper isPreventOnClick={isPreventOnClick}>
        <AssistanceImg src={assistanceIcon} alt="Assistance Icon" />
      </AssistanceImgWrapper>
      {!isInbox && !!unreadCount && (
        <NewMessagesWrapper>
          <IconCounter newMessagesCount={unreadCount} />
        </NewMessagesWrapper>
      )}

      <IndicatorWrapper>
        <IconIndicator />
      </IndicatorWrapper>
    </Root>
  )
}

const AssistanceImg = styled.img`
  height: ${convertPxToRem(30)};
  position: relative;
  top: ${convertPxToRem(2)};
  left: ${convertPxToRem(8)};
`
const AssistanceImgWrapper = styled.div`
  height: ${convertPxToRem(34)};
  width: ${convertPxToRem(34)};
  border-radius: 50%;
  background-color: ${sweetBlue};
  cursor: ${({ isPreventOnClick }) =>
    isPreventOnClick ? 'inherit' : 'pointer'};
  user-select: none;
  position: relative;
  top: ${convertPxToRem(-1)};
  left: ${convertPxToRem(-1)};
`
const Root = styled.div`
  position: relative;
  width: ${convertPxToRem(40)};
  height: ${convertPxToRem(40)};
  border: ${convertPxToRem(4)} solid ${primaryWhite};
  border-radius: ${convertPxToRem(20)};
  ${IconBoxShadow};
`
const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: ${convertPxToRem(-2)};
  right: ${convertPxToRem(-6)};
`
const NewMessagesWrapper = styled.div`
  position: absolute;
  top: ${convertPxToRem(-4)};
  right: ${convertPxToRem(-12)};
`

const mapDispatchToProps = (dispatch) => ({
  showThirdSectionModal: () => dispatch(setIsShowThirdSectionModal(true)),
  openNewChat: () => dispatch(startNewChat()),
  goToInbox: () => dispatch(openInbox()),
})

export default connect(null, mapDispatchToProps)(memo(NavigatorIconLightTheme))
