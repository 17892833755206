import { attach, createEvent, createStore, sample } from 'effector'
import { throttle } from 'patronum'

import { LocationAttributesInput } from 'src/shared/api/types'

import { buildAddressFromAttributes } from '../lib/address'
import { UserLocationsStep } from '../types'

import { $step, UserLocationsGate, stepChanged, stepChangedBack } from './core'
import { $editableLocation, locationSelected, locationDestroyed } from './edit'
import {
  completionSessionCreated,
  fetchCompletionsFx,
  geocodePlaceIdFx,
} from './google'

export const searchChanged = createEvent<string>()
export const completionSelected = createEvent<string>()

export const $completionsPending = createStore<boolean>(false)
export const $search = createStore<string>('')

export const $completions = createStore<
  google.maps.places.AutocompletePrediction[]
>([])

const geocodeCompletionFx = attach({ effect: geocodePlaceIdFx })

const completionFinalized = sample({
  clock: geocodeCompletionFx.doneData,
  filter: Boolean,
})

$search
  .on(searchChanged, (_, value) => value)
  .reset(completionFinalized, locationDestroyed, $step)

$completions.reset(completionFinalized, $step)

$editableLocation
  .on(completionFinalized, (state, update) =>
    state ? { ...state, ...update } : null,
  )
  .reset(UserLocationsGate.close)

const navigatedBackToSearch = sample({
  clock: stepChangedBack,
  source: $step,
  filter: (step) => step === UserLocationsStep.Search,
})

sample({
  clock: $search,
  fn: (value) => !!value,
  target: $completionsPending,
})

sample({
  clock: throttle({ source: $search.updates, timeout: 200 }),
  target: fetchCompletionsFx,
})

sample({
  clock: fetchCompletionsFx.doneData,
  target: $completions,
})

// Makes UI snappy
sample({
  clock: $search,
  filter: (value) => !value,
  target: $completions.reinit,
})

sample({
  clock: fetchCompletionsFx.doneData,
  fn: () => false,
  target: $completionsPending,
})

sample({
  clock: completionSelected,
  target: geocodeCompletionFx,
})

sample({
  clock: completionFinalized,
  fn: () => UserLocationsStep.Edit,
  target: stepChanged,
})

sample({
  clock: [locationSelected, navigatedBackToSearch],
  source: $editableLocation,
  filter: Boolean,
  fn: (attrs: LocationAttributesInput) => buildAddressFromAttributes(attrs),
  target: $search,
})

sample({
  clock: completionFinalized,
  target: completionSessionCreated,
})
