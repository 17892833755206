import styled from 'styled-components'

import { primaryDarkNavy, secondaryGrey } from 'src/theme/colors'
import { heading2_29, text1_16 } from 'src/theme/fonts'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

const CarouselItem = ({ item }) => {
  const { title, description, imageUrl } = item || {}
  return (
    <Root>
      <LogoContainer>
        <Logo src={imageUrl} alt={title} />
      </LogoContainer>
      <DescriptionWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DescriptionWrapper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: ${convertPxToRem(480)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: ${convertPxToRem(85)};
  ${mediaQueryFor.mobile} {
    padding-top: 0;
  }
`
const DescriptionWrapper = styled.div`
  height: ${convertPxToRem(100)};
`
const LogoContainer = styled.div`
  min-height: ${convertPxToRem(275)};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${convertPxToRem(30)};
`
const Title = styled.h1`
  margin-bottom: ${convertPxToRem(16)};
  text-align: center;
  color: ${primaryDarkNavy};
  ${heading2_29};
`

const Description = styled.div`
  max-width: ${convertPxToRem(400)};
  text-align: center;
  color: ${secondaryGrey};
  ${text1_16};
  margin-left: auto;
  margin-right: auto;
`

const Logo = styled.img`
  max-width: ${convertPxToRem(170)};
  ${mediaQueryFor.mobile} {
    max-width: ${convertPxToRem(140)};
  }
`

export default CarouselItem
