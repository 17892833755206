import { createApi, createStore } from 'effector'
import { persist } from 'effector-storage/local'

interface SessionStorage {
  hasTermsOfService: boolean
  hasLocationModal: boolean
  hasEarlyAccessModal: boolean

  dismissedPharmacyCards: string[]
}

const initialState: SessionStorage = {
  hasTermsOfService: true,
  hasLocationModal: true,
  hasEarlyAccessModal: true,

  dismissedPharmacyCards: [],
}

const $state = createStore<SessionStorage>(initialState)

const api = createApi($state, {
  sessionReset: () => $state.defaultState,

  termsOfServiceAccepted: (state): SessionStorage => ({
    ...state,
    hasTermsOfService: false,
  }),

  locationModalClosed: (state): SessionStorage => ({
    ...state,
    hasLocationModal: false,
  }),

  earlyAccessModalClosed: (state): SessionStorage => ({
    ...state,
    hasEarlyAccessModal: false,
  }),

  pharmacyCardDismissed: (state, id: string): SessionStorage => ({
    ...state,
    dismissedPharmacyCards: [...state.dismissedPharmacyCards, id],
  }),
})

persist({ store: $state, key: 'session' })

export const $$sessionStorage = {
  $state,
  ...api,
  '@@unitShape': () => ({
    acceptTermsOfService: api.termsOfServiceAccepted,

    closeLocationModal: api.locationModalClosed,
    closeEarlyAccessModal: api.earlyAccessModalClosed,

    dismissPharmacyCard: api.pharmacyCardDismissed,

    resetSession: api.sessionReset,
  }),
}
