import { createRouter } from '@tanstack/react-router'

import { rootRoute } from 'src/shared/router'

import { appScope } from '../config/scope'
import { App } from '../ui/App'
import { GlobalProvider } from '../ui/GlobalProvider'

import { routeTree } from './tree'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

rootRoute.update({ component: App })

export const router = createRouter({
  routeTree,
  context: { scope: appScope },
  Wrap: GlobalProvider,

  defaultStructuralSharing: true,
  defaultPreloadStaleTime: 0,
})
