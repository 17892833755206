// TODO: rename to `index.ts`
import { createRoute, Navigate, redirect } from '@tanstack/react-router'

import { drugSearchRoute, drugSearchScope } from 'src/pages/drugSearch/router'
import { searchRoute } from 'src/pages/drugSearch/Search'

import { configurationRoute } from './Configuration'
import { earlyAccessRoute } from './EarlyAccess'

function OpenSearch() {
  return <Navigate to={searchRoute.fullPath} />
}

const indexRoute = createRoute({
  getParentRoute: () => drugSearchRoute,
  path: '/',
  beforeLoad: () => {
    throw redirect({ to: searchRoute.fullPath, replace: true })
  },
})

const tree = drugSearchRoute
  .update({ notFoundComponent: OpenSearch })
  .addChildren({ indexRoute, searchRoute, configurationRoute })

export const drugSearchRouter = drugSearchScope.addChildren({
  earlyAccessRoute,
  drugSearchRoute: tree,
})
