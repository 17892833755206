import { lazy } from 'react'

import { Outlet } from '@tanstack/react-router'

const DevTools = lazy(() =>
  // eslint-disable-next-line import-x/no-extraneous-dependencies
  import('@tanstack/router-devtools')
    .then((module) => module.TanStackRouterDevtools)
    .then((component) => ({ default: component })),
)

export function App() {
  return (
    <>
      <Outlet />

      {__APP_ENV__ === 'development' && <DevTools />}
    </>
  )
}
