import { lazy } from 'react'

const EmailEntry = lazy(() => import('src/sections/SectionAuth/EmailEntry'))

const PasswordEntry = lazy(
  () => import('src/sections/SectionAuth/PasswordEntry'),
)

const CreateNewPassword = lazy(
  () => import('src/sections/SectionAuth/PasswordEntry/ResetPassword'),
)

export { EmailEntry, PasswordEntry, CreateNewPassword }
