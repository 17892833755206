import { convertPxToRem } from 'src/utils/responsiveHelpers'

function HomeIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        d="M20 7.91652V7.91655C19.9998 7.65849 19.8801 7.41506 19.6759 7.25737L10.5092 0.174041V0.174041C10.2089 -0.0583391 9.78948 -0.0583391 9.48919 0.174041L0.322522 7.25737H0.322522C0.118854 7.41538 -0.000225513 7.65875 3.20638e-07 7.91652V19.1665C3.90132e-07 19.6268 0.373096 19.9999 0.833334 19.9999H7.5C7.73012 19.9999 7.91667 19.8133 7.91667 19.5832V15.8332C7.91667 14.6826 8.84941 13.7499 10 13.7499C11.1506 13.7499 12.0833 14.6826 12.0833 15.8332V19.5832C12.0833 19.8133 12.2699 19.9999 12.5 19.9999H19.1667C19.6269 19.9999 20 19.6268 20 19.1665V7.91652Z"
        fill={fill}
      />
    </svg>
  )
}

export default HomeIcon
