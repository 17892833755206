import { createEffect } from 'effector'

import {
  authorizeIterable,
  AuthorizeIterableParams,
  destroyIterable,
  DestroyIterableParams,
  initializeIterable,
  InitializeIterableParams,
  IterableUtils,
} from '../lib/authorization'
import { IterableError } from '../lib/IterableError'

import { $hasAuthorization, $utils } from './core'

export const authorizeFx = createEffect<
  AuthorizeIterableParams,
  void,
  IterableError
>()

export const initializeFx = createEffect<
  InitializeIterableParams,
  IterableUtils,
  IterableError
>()

export const destroyFx = createEffect<
  DestroyIterableParams,
  void,
  IterableError
>()

$utils.on(initializeFx.doneData, (_, utils) => utils).on(destroyFx, () => null)

$hasAuthorization
  .on(authorizeFx.doneData, () => true)
  .on(destroyFx, () => false)

initializeFx.use(initializeIterable)
authorizeFx.use(authorizeIterable)
destroyFx.use(destroyIterable)
