import { sample } from 'effector'
import { createGate } from 'effector-react'
import { not } from 'patronum'

import { remap } from 'src/shared/lib/effector/remap'

import { RefreshTokenMutation } from '../api/refreshTokenMutation'
import { IterableUtils } from '../lib/authorization'

import { $hasAuthorization, $utils } from './core'
import { authorizeFx, destroyFx, initializeFx } from './effects'

interface IterableGateProps {
  refreshToken: RefreshTokenMutation
  userId: string
}

export const IterableControllerGate =
  createGate<IterableGateProps>('IterableGate')

/**
 * On mount, init the Iterable SDK
 * if not initialized already.
 */
sample({
  source: IterableControllerGate.state,
  filter: not($hasAuthorization),
  target: initializeFx,
})

/**
 * When initialization is finished, try and authorize
 */
sample({
  clock: initializeFx.doneData,
  source: remap(IterableControllerGate.state, 'userId'),
  fn: (userId, { setUserID }) => ({ userId, setUserID }),
  target: authorizeFx,
})

/**
 * When closing the app, e.g. logout, destroy Iterable
 */
sample({
  clock: IterableControllerGate.close,
  source: $utils,
  filter: (utils): utils is IterableUtils => utils !== null,
  target: destroyFx,
})
