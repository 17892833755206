import { trackMediaQuery } from '@withease/web-api'

import { setup } from './setup'

const query = {
  mobile: '(max-width: 576px)', // width <= 576px
  desktop: '(min-width: 577px)', // width > 576px
}

const { mobile, desktop } = trackMediaQuery(query, { setup })

export const $mobile = mobile.$matches
export const $desktop = desktop.$matches
