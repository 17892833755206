import { convertPxToRem } from 'src/utils/responsiveHelpers'

function ShowPasswordIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
  show = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.75C5.83334 3.75 2.27501 6.34167 0.833344 10C2.27501 13.6583 5.83334 16.25 10 16.25C14.1667 16.25 17.725 13.6583 19.1667 10C17.725 6.34167 14.1708 3.75 10 3.75ZM10 14.1667C7.70001 14.1667 5.83334 12.3 5.83334 10C5.83334 7.7 7.70001 5.83333 10 5.83333C12.3 5.83333 14.1667 7.7 14.1667 10C14.1667 12.3 12.3 14.1667 10 14.1667ZM10 7.5C8.62084 7.5 7.50001 8.62083 7.50001 10C7.50001 11.3792 8.62084 12.5 10 12.5C11.3792 12.5 12.5 11.3792 12.5 10C12.5 8.62083 11.3792 7.5 10 7.5Z"
        fill={fill}
      />
      {show && (
        <>
          <rect
            width="2"
            height="21.018"
            x="2"
            y="3.414"
            fill={fill}
            rx="1"
            transform="rotate(-45 2 3.414)"
          />
          <rect
            width="1.5"
            height="20.829"
            x="3.189"
            y="1.777"
            fill="#fff"
            rx=".75"
            transform="rotate(-45 3.19 1.777)"
          />
        </>
      )}
    </svg>
  )
}

export default ShowPasswordIcon
