import { convertPxToRem } from 'src/utils/responsiveHelpers'

function AddedToCalendarIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M7.974 13.75h-5.89c-.23 0-.417-.187-.417-.417V6.25c0-.23.186-.417.416-.417h11.25c.23 0 .417.187.417.417v1.725c.553-.077 1.114-.077 1.667 0V2.5c0-.46-.373-.833-.834-.833H12.5c-.115 0-.208-.094-.208-.209V.625c0-.345-.28-.625-.625-.625s-.625.28-.625.625V3.75c0 .345-.28.625-.625.625s-.625-.28-.625-.625V2.083c0-.23-.187-.416-.417-.416H5.833c-.115 0-.208-.094-.208-.209V.625C5.625.28 5.345 0 5 0s-.625.28-.625.625V3.75c0 .345-.28.625-.625.625s-.625-.28-.625-.625V2.083c0-.23-.187-.416-.417-.416H.833C.373 1.667 0 2.04 0 2.5v11.25c0 .92.746 1.667 1.667 1.667h6.307c-.077-.553-.077-1.114 0-1.667z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.166 14.583c0-2.991 2.425-5.416 5.417-5.416 2.99.003 5.413 2.426 5.416 5.416C20 17.575 17.574 20 14.583 20c-2.992 0-5.417-2.425-5.417-5.417zm3.03-.035c-.165 0-.324.066-.442.183-.117.118-.183.277-.183.443 0 .166.066.325.183.442l1.25 1.25c.256.257.611.39.973.364.362-.025.695-.207.912-.497l2.42-3.23c.207-.277.151-.668-.125-.875-.276-.207-.668-.152-.875.125l-2.42 3.228-1.25-1.25c-.118-.117-.277-.183-.443-.183z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default AddedToCalendarIcon
