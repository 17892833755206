import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { and, condition, equals, not } from 'patronum'

import { Coordinates } from 'src/entities/geolocation'
import { LocationKindType } from 'src/shared/api/types'
import { DEFAULT_COORDS } from 'src/utils/locationHelpers'
import persistentStorageInterface from 'src/utils/storage/persistent'

import { UserLocation } from '../api/location'
import { mapLocationToAttributes } from '../lib/address'
import { DeviceLocationState } from '../types'

import {
  $currentGeolocation,
  $currentGeolocationPending,
  $currentGeolocationState,
  CURRENT_LOCATION_ID,
  currentGeolocationRequested,
} from './current'
import {
  $locations,
  $locationsReceived,
  createUserLocationFx,
  updateUserLocationFx,
} from './requests'

export const locationPreferred = createEvent<string>()

export const deviceLocationSelected = createEvent<void>()

export const saveLocationToStorageFx = createEffect<Coordinates, void>()

export const $preferCurrentLocation = createStore<boolean>(false)

export const $preferredLocation = combine(
  $locations,
  $currentGeolocation,
  $preferCurrentLocation,
  (locations, current, isCurrentPreferred) =>
    [isCurrentPreferred ? current : null, ...locations, current]
      .filter((location): location is UserLocation => !!location)
      .find((location) => location.isPreferred) ?? null,
)

export const $locationsReady = and(
  $locationsReceived,
  not($currentGeolocationPending),
)

export const $preferredLocationId = $preferredLocation.map((location) =>
  location ? location.id : null,
)

export const $hasPreferredLocation = $preferredLocation.map(Boolean)

export const $availableLocationKinds = $locations.map<LocationKindType[]>(
  (locations) => {
    const used = new Set(locations.map(({ kind }) => kind))

    return [
      !used.has(LocationKindType.HomeAddress) && LocationKindType.HomeAddress,
      !used.has(LocationKindType.WorkAddress) && LocationKindType.WorkAddress,
      LocationKindType.OtherAddress,
    ].filter((kind): kind is LocationKindType => Boolean(kind))
  },
)

sample({
  clock: locationPreferred,
  fn: (id) => id === CURRENT_LOCATION_ID,
  target: $preferCurrentLocation,
})

sample({
  clock: createUserLocationFx.doneData,
  fn: () => false,
  target: $preferCurrentLocation,
})

sample({
  clock: sample({
    clock: locationPreferred,
    source: $locations,
    filter: (_, id) => id !== CURRENT_LOCATION_ID,
    fn: (locations, target) => locations.find(({ id }) => id === target),
  }),
  filter: (location): location is UserLocation => !!location,
  fn: (location: UserLocation) => ({
    id: location.id,
    attributes: { ...mapLocationToAttributes(location), is_preferred: true },
  }),
  target: updateUserLocationFx,
})

sample({
  clock: $preferredLocation.updates,
  fn: (location) => location || DEFAULT_COORDS,
  target: saveLocationToStorageFx,
})

saveLocationToStorageFx.use(({ longitude, latitude }) =>
  persistentStorageInterface.modify.setLocation({ longitude, latitude }),
)

condition({
  source: deviceLocationSelected,
  if: equals($currentGeolocationState, DeviceLocationState.Aquired),
  then: locationPreferred.prepend(() => CURRENT_LOCATION_ID),
  else: currentGeolocationRequested,
})
