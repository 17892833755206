import { PropsWithChildren } from 'react'

import { ApolloProvider } from '@apollo/client'
import { Provider as EffectorProvider } from 'effector-react'

import client from 'src/apollo/client'

import { appScope } from '../config/scope'

export function GlobalProvider({ children }: PropsWithChildren) {
  return (
    <EffectorProvider value={appScope}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </EffectorProvider>
  )
}
