import {
  $isPendingSelection,
  $preferredPharmacyCoordinates,
  $hasPharmacies,
  $hasExtendOption,
  $isShortList,
  $foundPharmacies,
} from './model/core'

export {
  pharmaciesListExpanded,
  preferredPharmacyChosen,
  selectPharmacyFx,
  INITIAL_LIST_LENGTH,
} from './model/core'
export { drugSearchPharmaciesQuery } from './api/pharmaciesSearchQuery'
export type { PharmaciesResult } from './api/pharmaciesSearchQuery/types'
export { MockPreferredPharmaciesQuery } from './api/pharmaciesSearchQuery/mocks'

export const $$pharmacySearch = {
  $isPendingSelection,
  $preferredPharmacyCoordinates,
  $hasPharmacies,
  $hasExtendOption,
  $isShortList,
  $foundPharmacies,
}
