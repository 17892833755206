import { lazy } from 'react'

const SignUp = lazy(() => import('src/sections/SectionRegistration'))

const GetAssistance = lazy(
  () =>
    import(
      'src/sections/SectionRegistration/pages/GetAssistance/GetAssistance'
    ),
)

const GetAssistanceSuccess = lazy(
  () =>
    import(
      'src/sections/SectionRegistration/pages/GetAssistance/GetAssistanceSuccess'
    ),
)

export { SignUp, GetAssistance, GetAssistanceSuccess }
