import { createRoute } from '@tanstack/react-router'

import { drugSearchRouter } from 'src/pages/drugSearch/tree'
import { secureScope, publicScope, rootRoute } from 'src/shared/router'

const indexRoute = createRoute({
  getParentRoute: () => publicScope,
  path: '/',
})

const secureTree = secureScope.addChildren({ drugSearchRouter })

const publicTree = publicScope.addChildren({ indexRoute })

export const routeTree = rootRoute.addChildren({ secureTree, publicTree })
