import { createRoute, redirect } from '@tanstack/react-router'

import { $isInEarlyAccess } from 'src/entities/earlyAccess/model'
import { secureScope } from 'src/shared/router'

export const drugSearchScope = createRoute({
  getParentRoute: () => secureScope,
  id: '@drug-search',
})

export const drugSearchRoute = createRoute({
  getParentRoute: () => drugSearchScope,
  path: '/drug-search',
  beforeLoad: ({ context: { scope } }) => {
    /* TODO: check product ff */
    const isEarlyAccess = scope.getState($isInEarlyAccess)
    if (isEarlyAccess) throw redirect({ to: '/drug-search/early-access' })
  },
})
