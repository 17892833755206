import { secondaryGrey } from 'src/theme/colors'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

function NewMessageIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = secondaryGrey,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <path
        d="M11.4279 12.7314C11.269 12.8911 11.0794 13.0168 10.8706 13.1008L7.93463 14.2799C7.31759 14.5279 6.61274 14.3827 6.14277 13.9109C5.67281 13.439 5.52822 12.7313 5.7752 12.1118L6.95043 9.16409C7.03374 8.95427 7.15863 8.76363 7.31753 8.60373L12.9794 2.92255H2.98955C1.56022 2.92392 0.401734 4.08668 0.399902 5.52173V17.0008C0.40219 18.4357 1.56041 19.5982 2.98955 19.6H14.422C15.851 19.5982 17.009 18.4356 17.0108 17.0008V7.1261L11.4279 12.7314Z"
        fill={fill}
      />
      <path
        d="M16.7442 0.908781L8.19875 9.48851C8.1591 9.52863 8.12777 9.57625 8.10656 9.6286L6.93216 12.5763C6.87025 12.7314 6.90654 12.9086 7.02435 13.0266C7.14264 13.1429 7.31781 13.1788 7.47202 13.1184L10.4047 11.9393C10.457 11.9182 10.5044 11.8867 10.5442 11.8467L19.0922 3.26781C19.5242 2.84923 19.6976 2.22922 19.5457 1.64608C19.3939 1.06293 18.9405 0.6074 18.3598 0.454569C17.7791 0.301738 17.1615 0.47539 16.7442 0.908781Z"
        fill={fill}
      />
    </svg>
  )
}

export default NewMessageIcon
