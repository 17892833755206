import { createEvent, sample } from 'effector'

import { $$device } from 'src/shared/lib/device'
import { $$unstable_router } from 'src/shared/router'

import { router } from '../router'

export const appStarted = createEvent<void>()

sample({
  clock: appStarted,
  target: $$device.setup,
})

sample({
  clock: appStarted,
  fn: () => router,
  target: $$unstable_router.setup,
})
