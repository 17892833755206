import { convertPxToRem } from 'src/utils/responsiveHelpers'

function NotificationIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 20 21"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#2E3141"
          d="M17.5 14.833c-.332 0-.65-.131-.884-.366-.235-.234-.367-.552-.367-.884V9.532c.049-1.583-.471-3.13-1.465-4.363-.993-1.232-2.395-2.069-3.951-2.358V1.083c0-.22-.088-.433-.244-.589-.157-.156-.369-.244-.59-.244-.22 0-.433.088-.589.244-.156.156-.244.368-.244.59V2.81C7.609 3.1 6.208 3.937 5.214 5.169 4.221 6.402 3.701 7.95 3.75 9.532v4.051c0 .332-.131.65-.366.884-.234.235-.552.366-.884.366-.22 0-.433.088-.589.244-.156.157-.244.369-.244.59 0 .22.088.433.244.589.156.156.368.244.59.244h15c.22 0 .432-.088.589-.244.156-.156.244-.368.244-.59 0-.22-.088-.432-.244-.589-.157-.156-.369-.244-.59-.244zM11.863 17.75H8.136c-.051 0-.1.019-.139.053-.038.034-.062.081-.068.132-.009.077-.013.154-.013.232 0 .552.22 1.082.61 1.473.39.39.92.61 1.473.61s1.083-.22 1.473-.61c.391-.39.61-.92.61-1.473 0-.078-.004-.155-.013-.232-.005-.051-.03-.098-.067-.132-.039-.035-.088-.053-.14-.053z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(0 .25)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotificationIcon
