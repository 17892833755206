import { reflect, variant } from '@effector/reflect'
import styled from 'styled-components'

import Spinner from 'src/components/Spinner'
import { SearchBarButton } from 'src/features/SearchBar'
import { useScopedTranslation } from 'src/shared/lib/useScopedTranslation'
import { Sprite } from 'src/shared/ui/Sprite'
import {
  secondaryBackgroundGrey,
  secondaryGrey,
  secondaryMidGrey,
} from 'src/theme/colors'
import mediaQueryFor from 'src/theme/mediaQueries'
import { convertPxToRem } from 'src/utils/responsiveHelpers'

import { locationInitialized } from '../../model/edit'
import { $locationsFetchPending } from '../../model/requests'

import { DeviceLocationSection } from './DeviceLocationSection'
import { SavedLocations } from './SavedLocations'

interface LocationsListViewProps {
  withDeviceLocationCard: boolean
}

function LocationsListView({ withDeviceLocationCard }: LocationsListViewProps) {
  const t = useScopedTranslation('translation.profile.preferred_locations')

  return (
    <Root>
      <SearchAddressButton text={t('input_placeholder')} />

      <SavedLocations />

      {withDeviceLocationCard && <DeviceLocationSection />}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${convertPxToRem(24)};

  height: 100%;
  width: 100%;

  ${mediaQueryFor.mobile} {
    gap: ${convertPxToRem(16)};
  }
`

const SearchAddress = reflect({
  view: SearchBarButton,
  bind: {
    onClick: locationInitialized,
    dataTestProp: 'searchAddressButton',
    icon: <Sprite name="misc/locator" />,
  },
})

const SearchAddressButton = styled(SearchAddress)`
  color: ${secondaryMidGrey};
  background-color: ${secondaryBackgroundGrey};

  & > svg {
    color: ${secondaryGrey};
  }
`

export const LocationsList = variant({
  if: $locationsFetchPending,
  then: () => (
    <Root>
      <Spinner />
    </Root>
  ),
  else: LocationsListView,
})
