import { convertPxToRem } from 'src/utils/responsiveHelpers'

function OptionsForCareIcon({
  width = convertPxToRem(20),
  height = convertPxToRem(20),
  fill = 'none',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 10.5954V12.7378C5.75 12.8799 5.80645 13.0162 5.90694 13.1166C6.00743 13.2171 6.14372 13.2736 6.28583 13.2736H7.89333C8.00384 13.2736 8.10982 13.3175 8.18796 13.3956C8.2661 13.4737 8.31 13.5797 8.31 13.6902V15.2977C8.30989 15.3679 8.32365 15.4376 8.3505 15.5025C8.37735 15.5675 8.41675 15.6265 8.46645 15.6762C8.51615 15.7259 8.57518 15.7653 8.64013 15.7921C8.70509 15.819 8.77471 15.8327 8.845 15.8326H10.9883C11.0587 15.8327 11.1283 15.819 11.1933 15.7921C11.2583 15.7653 11.3174 15.7259 11.3672 15.6762C11.4169 15.6265 11.4564 15.5675 11.4834 15.5026C11.5103 15.4376 11.5242 15.368 11.5242 15.2977V13.6902C11.5242 13.5797 11.5681 13.4737 11.6462 13.3956C11.7243 13.3175 11.8303 13.2736 11.9408 13.2736H13.5475C13.6896 13.2736 13.8259 13.2171 13.9264 13.1166C14.0269 13.0162 14.0833 12.8799 14.0833 12.7378V10.5954C14.0833 10.525 14.0695 10.4553 14.0425 10.3903C14.0156 10.3253 13.9761 10.2662 13.9264 10.2165C13.8766 10.1667 13.8176 10.1273 13.7526 10.1003C13.6875 10.0734 13.6179 10.0595 13.5475 10.0595H11.9408C11.8303 10.0595 11.7243 10.0156 11.6462 9.93751C11.5681 9.85937 11.5242 9.7534 11.5242 9.6429V8.03547C11.5239 7.8935 11.4674 7.75743 11.3669 7.65713C11.2665 7.55683 11.1303 7.50049 10.9883 7.50049H8.845C8.77467 7.50049 8.70504 7.51435 8.64007 7.54129C8.57511 7.56823 8.51609 7.60771 8.4664 7.65747C8.41671 7.70724 8.37732 7.76631 8.35048 7.83132C8.32365 7.89632 8.30989 7.96597 8.31 8.0363V9.64373C8.31 9.75423 8.2661 9.86021 8.18796 9.93834C8.10982 10.0165 8.00384 10.0604 7.89333 10.0604H6.28583C6.14387 10.0604 6.0077 10.1167 5.90724 10.217C5.80677 10.3173 5.75022 10.4534 5.75 10.5954Z"
        fill={fill}
      />
      <path
        d="M16.1667 2.50073H13.875C13.8197 2.50073 13.7668 2.52268 13.7277 2.56175C13.6886 2.60082 13.6667 2.65381 13.6667 2.70906V3.959C13.6666 3.98639 13.6719 4.01353 13.6823 4.03886C13.6927 4.06418 13.7081 4.08719 13.7274 4.10656C13.7468 4.12592 13.7698 4.14126 13.7951 4.15169C13.8205 4.16212 13.8476 4.16744 13.875 4.16733H15.75C15.8605 4.16733 15.9665 4.21122 16.0446 4.28936C16.1228 4.3675 16.1667 4.47347 16.1667 4.58398V17.9167C16.1667 18.0272 16.1228 18.1332 16.0446 18.2114C15.9665 18.2895 15.8605 18.3334 15.75 18.3334H4.08333C3.97283 18.3334 3.86685 18.2895 3.78871 18.2114C3.71057 18.1332 3.66667 18.0272 3.66667 17.9167V4.58398C3.66667 4.47347 3.71057 4.3675 3.78871 4.28936C3.86685 4.21122 3.97283 4.16733 4.08333 4.16733H5.95833C5.98572 4.16744 6.01286 4.16212 6.03819 4.15169C6.06351 4.14126 6.08652 4.12592 6.10589 4.10656C6.12526 4.08719 6.1406 4.06418 6.15103 4.03886C6.16146 4.01353 6.16678 3.98639 6.16667 3.959V2.70906C6.16667 2.65381 6.14472 2.60082 6.10565 2.56175C6.06658 2.52268 6.01359 2.50073 5.95833 2.50073H3.66667C3.22464 2.50073 2.80072 2.67632 2.48816 2.98887C2.17559 3.30141 2 3.72532 2 4.16733V18.3334C2 18.7754 2.17559 19.1993 2.48816 19.5118C2.80072 19.8244 3.22464 20 3.66667 20H16.1667C16.6087 20 17.0326 19.8244 17.3452 19.5118C17.6577 19.1993 17.8333 18.7754 17.8333 18.3334V4.16733C17.8333 3.72532 17.6577 3.30141 17.3452 2.98887C17.0326 2.67632 16.6087 2.50073 16.1667 2.50073Z"
        fill={fill}
      />
      <path
        d="M8.24978 5.41729H11.5831C11.8041 5.41729 12.0161 5.32949 12.1724 5.17322C12.3286 5.01695 12.4164 4.80499 12.4164 4.58399V2.50075C12.4154 2.11523 12.3255 1.73514 12.1537 1.39002C11.9819 1.0449 11.7328 0.744043 11.4258 0.510829C11.1189 0.277614 10.7622 0.118326 10.3837 0.0453421C10.0051 -0.0276414 9.6148 -0.0123541 9.24311 0.090016C8.70831 0.246113 8.23972 0.573813 7.90961 1.02258C7.5795 1.47134 7.4062 2.01624 7.41645 2.57324V4.58399C7.41645 4.80499 7.50424 5.01695 7.66052 5.17322C7.8168 5.32949 8.02876 5.41729 8.24978 5.41729ZM9.91645 1.66745C10.0401 1.66745 10.1609 1.7041 10.2637 1.77277C10.3665 1.84145 10.4466 1.93906 10.4939 2.05325C10.5412 2.16745 10.5536 2.29311 10.5294 2.41435C10.5053 2.53558 10.4458 2.64694 10.3584 2.73434C10.271 2.82175 10.1596 2.88127 10.0384 2.90539C9.91714 2.9295 9.79147 2.91712 9.67727 2.86982C9.56306 2.82252 9.46545 2.74241 9.39678 2.63964C9.3281 2.53686 9.29145 2.41603 9.29145 2.29242C9.29145 2.12667 9.35729 1.9677 9.4745 1.8505C9.59171 1.73329 9.75069 1.66745 9.91645 1.66745Z"
        fill={fill}
      />
    </svg>
  )
}

export default OptionsForCareIcon
